import axios from "axios";
import { toast } from "react-toastify";
import { notifyEnv } from "../config";
import * as TYPES from "./constants";
import {
  fetchOrders,
  fetchSingleOrder,
  paymentDisputeService,
  updateStatus,
  fetchOrderHistory,
} from "./service";

export const fetchOrdersAction = (
  status: string,
  order_number: string,
  date: {
    from: string,
    to: string,
  },
  page,
  limit
) => {
  return (dispatch: Function) => {
    dispatch({
      type: TYPES.GET_ORDERS,
    });

    fetchOrders(status, order_number, date, page, limit).then((Res) => {
      dispatch({
        type: TYPES.LIST_ORDERS,
        payload: Res,
      });
    });
    // .catch((err) => {
    //   dispatch({
    //     type: TYPES.ORDER_ERROR,
    //     payload: err.response.data,
    //   });
    // });
  };
};

export const fetchSingleOrderAction = (order_id: number) => {
  return (dispatch: Function) => {
    dispatch({
      type: TYPES.GET_SINGLE_ORDER,
    });

    fetchOrderHistory(order_id)
      .then((res) => {
        dispatch({
          type: TYPES.GET_STATUS,
          payload: res,
        });

        return fetchSingleOrder(order_id);
      })
      .then((res) => {
        dispatch({
          type: TYPES.LIST_ORDER,
          payload: res,
        });
      })
      .catch((Err) => {
        dispatch({
          type: TYPES.ORDER_ERROR,
          payload: Err.response.data,
        });
      });
  };
};

export const cancelOrder = (order_id, status, payload) => {
  return (dispatch) => {
    dispatch({
      type: TYPES.CANCEL_ORDER,
    });

    updateStatus(order_id, status, payload)
      .then((res) => {
        dispatch({
          type: TYPES.ORDER_STATUS_UPDATE,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const updateOrderStatus = (order_id, status, payload) => {
  axios
    .post("https://notifications.quatrixglobal.com/instant-send", {
      rider_id: payload?.driver_id,
      env: notifyEnv,
      message: null,
    })
    .then(() => {})
    .catch((err) => {});
  return (dispatch) => {
    dispatch({
      type: TYPES.UPDATE,
    });

    updateStatus(order_id, status, payload)
      .then((res) => {
        dispatch({
          type: TYPES.ORDER_STATUS_UPDATE,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const paymentDispute = (data) => {
  return (dispatch) => {
    dispatch({
      type: TYPES.UPDATE,
    });

    paymentDisputeService(data)
      .then((res) => {
        toast.success("Transaction added");
        dispatch({
          type: TYPES.UPDATE,
        });
        dispatch({
          type: TYPES.GET_SINGLE_ORDER,
        });

        return fetchSingleOrder(data.order_id);
      })
      .then((res) => {
        dispatch({
          type: TYPES.LIST_ORDER,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const syncOrder = (order_id) => {
  return (dispatch: Function) => {
    fetchOrderHistory(order_id)
      .then((res) => {
        dispatch({
          type: TYPES.GET_STATUS,
          payload: res,
        });

        return fetchSingleOrder(order_id);
      })
      .then((res) => {
        dispatch({
          type: TYPES.SYNC_ORDER,
          payload: res,
        });
      })
      .catch((Err) => {
        dispatch({
          type: TYPES.ORDER_ERROR,
          payload: Err.response.data,
        });
      });
  };
};
